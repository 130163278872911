import React, { useEffect, useState } from "react"
import { CircularProgress, useMediaQuery } from "@mui/material"
import Product from "models/Product"
import Provider from "models/Provider"
import ProductComponent from "components/content/term-deposits/product-component"
import { ListHeaderProvider } from "components/content/list-header"
import * as styles from "./style.module.scss"
import Layout from "components/layout"
import ReturnLinkChain from "components/content/return-link-chain"
import { Typography } from "components/typography"
import Sort from "components/content/sort"
import FilterMenu from "components/content/filter-menu"
import * as Clickable from "components/clickable"
import { makeSchemaOrgProvider } from "components/schema"
import { TermDepositValuesHeader } from "components/content/product-values-header"
import InputFilter from "components/content/term-deposits/input-filter"
import AppManager from "services/app-manager"
import Pagination from "components/pagination"
import { compileSchemaOrgQa } from "components/schema"
import Markdown from "components/markdown"
import TermDepositProduct, { rate } from "models/product-models/TermDepositProduct"
import TermDepositProvider from "models/provider-models/TermDepositProvider"
import TermDepositFeature from "models/feature-models/TermDepositFeature"
import { LazyLoadImage } from "react-lazy-load-image-component"
import { providerImage } from "components/survey/utils"
import ReactMarkdown from "markdown-to-jsx"
import { Link } from "gatsby"
import Offer from "models/Offer"
import { DataCollector, DataCollectorAction } from "@sog/sdk"
import { TermDepositAds, SavingsAccountAds } from "components/content/ads"
import Faq from "components/content/faq"
import FactCheckedDialog from "components/content/fact-checked-dialog"

const ProviderTemplate = (props: any) => {
  const { categoryJson, dateUpdated, ads, qa }: { categoryJson: Provider; dateUpdated: string; ads: any; qa?: any } = JSON.parse(props.pageContext.p)
  const isTablet = !useMediaQuery("(min-width:1024px)")
  const isWide = useMediaQuery("(min-width:768px)")
  const [filter, setFilter] = useState<string[]>([])
  const [sort, setOnlySort] = useState("Interest Rate")
  const [tempFilter, setTempFilter] = useState<string[]>([])
  const [linkFirst, setLinkFirst] = useState(true)
  const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
  const dateString = Number(dateUpdated.substring(8, 10)) + " " + months[parseInt(dateUpdated.substring(5, 7)) - 1] + " " + dateUpdated.substring(0, 4)
  const [isLoading, setLoading] = useState(false)
  // const [open, setOpen] = useState(false)
  const [page, setPage] = useState(0)
  const [offersPerPage, setOffersPerPage] = useState(10)

  const appManager = AppManager.getInstance()

  const [depositAmount, setDepositAmount] = useState(appManager.getDepositInvestmentAmount())
  const [depositTerm, setDepositTerm] = useState(appManager.getDepositInvestmentTerm())

  const updateFilter = (v: string[]) => {
    setPage(0)
    setFilter(v)
  }

  const updateOnlySort = (v: string) => {
    setPage(0)
    setOnlySort(v)
  }

  const updateTempFilter = (v: string[]) => {
    setPage(0)
    setTempFilter(v)
  }

  const updateDepositAmount = (v: number) => {
    setPage(0)
    setDepositAmount(v)
  }

  const updateDepositTerm = (v: number) => {
    setPage(0)
    setDepositTerm(v)
  }

  function makeProductComponent(product: Product, key: number, rateId: string, isLast?: boolean, offerFilter?: string) {
    return (
      <div key={product.id.toString() + "-" + key.toString() + "-" + rateId.toString()} style={{ borderBottom: isLast && !isLast ? "" : "0.5pt #D2D2D2 solid" }} className={styles.productComponent}>
        <div className={styles.innerProductComponent}>
          <ProductComponent product={TermDepositProduct.Initialise(product)} rateId={rateId} learnMore offerFilter={offerFilter} isTablet={isTablet} />
        </div>
      </div>
    )
  }

  function isProductComponent(component: JSX.Element) {
    return component.props.className && component.props.className === styles.productComponent
  }

  function makeCategoryHeader(headerName: string) {
    return (
      // <div key={headerName} className={!isWide || isTablet ? style.listHeaderMobile : style.listHeader}>
      //   <div style={{ marginLeft: "20px", color: "white", fontFamily: "Arboria-Bold, sans-serif", fontWeight: 700, lineHeight: "40px", fontSize: "20px" }}>{headerName}</div>
      // </div>
      <ListHeaderProvider key={headerName} isMobile={!isWide || isTablet}>
        {headerName}
      </ListHeaderProvider>
    )
  }

  function setSort(name: string) {
    setLoading(true)
    updateOnlySort(name)
  }

  function compare(a: JSX.Element, b: JSX.Element, sort: string): number {
    if (isProductComponent(a) && isProductComponent(b)) {
      const productA: TermDepositProduct = TermDepositProduct.Initialise(a.props.children.props.children.props.product)
      const rateIdA = a.props.children.props.children.props.rateId
      const productB: TermDepositProduct = TermDepositProduct.Initialise(b.props.children.props.children.props.product)
      const rateIdB = b.props.children.props.children.props.rateId
      const maxMinTermA = TermDepositProduct.getTermInMonths(productA.rates[rateIdA].sogMinTerm)
      const maxMinTermB = TermDepositProduct.getTermInMonths(productB.rates[rateIdB].sogMinTerm)
      switch (sort) {
        case "Interest Rate":
          return productB.rates[rateIdB].interestRate - productA.rates[rateIdA].interestRate
        case "Term":
          return maxMinTermA - maxMinTermB
        case "Link First":
          const hasOfferLinkA = Object.keys(productA.offers).some(oid => productA.offers[oid].active === "Y" && productA.offers[oid].landingPageUrl)
          const hasOfferLinkB = Object.keys(productB.offers).some(oid => productB.offers[oid].active === "Y" && productB.offers[oid].landingPageUrl)
          if (hasOfferLinkA && !hasOfferLinkB) {
            return -1
          } else if (hasOfferLinkB && !hasOfferLinkA) {
            return 1
          } else {
            productA.rates[rateIdA].interestRate - productB.rates[rateIdB].interestRate
          }
      }
    }
    return 0
  }

  let fixedTerm = false
  let category: TermDepositProvider | TermDepositFeature | null = null
  if ("big4" in categoryJson) {
    category = TermDepositProvider.Initialise(categoryJson) as TermDepositProvider
  } else {
    category = TermDepositFeature.Initialise(categoryJson)
    switch (category.name) {
      case "3 Month Rates":
      case "6 Month Rates":
      case "12 Month Rates": {
        fixedTerm = true
      }
    }
  }

  let productList: JSX.Element[] = category.getProductList(makeProductComponent, makeCategoryHeader)

  // get best rate
  const inputMatchedRates: rate[] = []
  productList.forEach(productComponent => {
    if (productComponent) {
      if (isProductComponent(productComponent)) {
        const propsProduct: TermDepositProduct = TermDepositProduct.Initialise(productComponent.props.children.props.children.props.product)
        const rateId: number = productComponent.props.children.props.children.props.rateId
        if (propsProduct.rates && rateId in propsProduct.rates) {
          const rate = propsProduct.rates[rateId]
          const minTerm = TermDepositProduct.getTermInMonths(rate.sogMinTerm)
          const maxTerm = Math.max(TermDepositProduct.getTermInMonths(rate.sogMaxTerm || ""), minTerm + 1)
          const appFreq = TermDepositProduct.getTermInMonths(rate.applicationFreq || "")
          if (minTerm <= depositTerm && maxTerm > depositTerm && rate.minInvestment <= depositAmount && (!rate.maxInvestment || rate.maxInvestment >= depositAmount) && (rate.applicationFreq || appFreq === 12)) {
            // matched rate
            inputMatchedRates.push(rate)
          }
        }
      }
    }
  })
  let bestMatchedRate: rate | null = inputMatchedRates.length === 0 ? null : inputMatchedRates[0]
  inputMatchedRates.forEach(r => {
    if (bestMatchedRate === null || r.interestRate > bestMatchedRate.interestRate) {
      bestMatchedRate = r
    }
  })

  // Filter out products
  const productGroups: JSX.Element[][] = []
  productList.forEach(productComponent => {
    if (productComponent) {
      if (isProductComponent(productComponent)) {
        const propsProduct: TermDepositProduct = TermDepositProduct.Initialise(productComponent.props.children.props.children.props.product)
        const rateId: number = productComponent.props.children.props.children.props.rateId

        // Filter out products by feature
        // TODO

        // Filter out products by user input filter
        if (Object.keys(propsProduct.rates).length > 0 && rateId in propsProduct.rates) {
          const rate = propsProduct.rates[rateId]
          const minAmount = rate.minInvestment ? (rate.minInvestment as number) : propsProduct.minInvestment ? propsProduct.minInvestment : -1
          const maxAmount = rate.maxInvestment ? (rate.maxInvestment as number) : propsProduct.maxInvestment ? propsProduct.maxInvestment : -1

          if (!(minAmount <= depositAmount && maxAmount >= depositAmount)) {
            return
          }

          const appFreq = TermDepositProduct.getTermInMonths(rate.applicationFreq || "")
          if (rate.applicationFreq !== "MATURITY" && appFreq !== 12) {
            // maturity/annual only
            return
          }

          const minTerm = TermDepositProduct.getTermInMonths(rate.sogMinTerm)
          const maxTerm = Math.max(TermDepositProduct.getTermInMonths(rate.sogMaxTerm || ""), minTerm + 1)

          if (appFreq === 12 && minTerm <= 12) {
            // filter out non-maturity rates, if one at maturity exists for same term
            if (
              Object.values(propsProduct.rates).some(r => {
                return TermDepositProduct.getTermInMonths(r.sogMinTerm) === minTerm && r.applicationFreq === "MATURITY"
              })
            ) {
              return
            }
          }

          if (minTerm > depositTerm || maxTerm <= depositTerm) {
            // over or under the specified term
            if ((bestMatchedRate !== null && bestMatchedRate.interestRate >= rate.interestRate) || minTerm > depositTerm) {
              // under interest of best rate, or over the specified term
              if (depositTerm < 12) {
                // only include within three months
                if (minTerm > depositTerm + 3 || maxTerm <= depositTerm - 3) {
                  return
                }
              } else if (depositTerm < 24) {
                // only include within six months
                if (minTerm > depositTerm + 6 || maxTerm <= depositTerm - 6) {
                  return
                }
              } else {
                // include all rates 12+ months
                if (minTerm < 12) {
                  return
                }
              }
            }
          }
        }

        // Filter out products by filter check box
        let providerFilter: boolean[] = []
        filter.forEach(filterType => {
          switch (filterType) {
            case "Major Banks":
              if (propsProduct.providerId === "00100" || propsProduct.providerId === "00200" || propsProduct.providerId === "00300" || propsProduct.providerId === "00400") {
                providerFilter.push(true)
              } else {
                providerFilter.push(false)
              }
              break
            case "Challengers":
              if (!(propsProduct.providerId === "00100" || propsProduct.providerId === "00200" || propsProduct.providerId === "00300" || propsProduct.providerId === "00400")) {
                providerFilter.push(true)
              } else {
                providerFilter.push(false)
              }
              break
            case "Non-banks":
              providerFilter.push(false)
              break
          }
        })
        if (providerFilter.includes(true) || providerFilter.length === 0) {
          // include the rate
          productGroups[productGroups.length - 1].push(productComponent)
        }
      } else {
        if (sort === "Provider") {
          productGroups.push([productComponent])
        } else {
          productGroups.push([])
        }
      }
    }
  })

  productList = []
  productGroups.forEach(group => {
    if (group.length > 1 || (sort !== "Provider" && group.length > 0)) {
      productList = productList.concat(group)
    }
  })

  // Add "No Products" component if empty
  if (productList.length === 0) {
    productList.push(<div style={{ textAlign: "center", fontSize: "24px", marginTop: "30px", fontFamily: "Arboria-Book" }}>No Products</div>)
  } else {
    // Sort products
    switch (sort) {
      case "Interest Rate":
        productList.sort((a, b) => compare(a, b, sort))
        break
      case "Term":
        productList.sort((a, b) => compare(a, b, sort))
        break
    }
    if (linkFirst) {
      productList.sort((a, b) => compare(a, b, "Link First"))
    }
  }

  // sortList
  const sortList: string[] = ["Interest Rate", "Term"]

  // filterList
  const filterList: { [type: string]: string[] } = {}

  if (!("big4" in categoryJson)) {
    filterList["provider"] = ["Major Banks", "Challengers", "Non-banks"]
  }

  // filterAmounts
  const filterAmounts: { [id: string]: number } = {
    "Major Banks": 0,
    Challengers: 0,
    "Non-banks": 0,
  }

  // filterAmounts
  let totalAmount: number = 0
  productList.forEach((productComponent: JSX.Element) => {
    if (productComponent.props.children !== "No Products") {
      // if (typeof productComponent.props.children.props.children !== "string") {
      if (isProductComponent(productComponent)) {
        // less hacky
        totalAmount += 1
        const product: TermDepositProduct = productComponent.props.children.props.children.props.product
        const rateId = productComponent.props.children.props.children.props.rateId
        // filterAmounts
        if (product.providerId === "00100" || product.providerId === "00200" || product.providerId === "00300" || product.providerId === "00400") {
          filterAmounts["Major Banks"] += 1
        } else if (!(product.providerId === "00100" || product.providerId === "00200" || product.providerId === "00300" || product.providerId === "00400")) {
          filterAmounts["Challengers"] += 1
        } else {
          filterAmounts["Non-banks"] += 1
        }
      }
    }
  })

  // Pagination
  const paginatedProductLists: JSX.Element[][] = []

  while (productList.length) {
    paginatedProductLists.push(productList.splice(0, offersPerPage))
  }

  productList = paginatedProductLists[page]

  const currentOfferShown = (offersPerPage * page + 1).toString() + " - " + (offersPerPage * page + paginatedProductLists[page].length).toString()

  const paginationRef = React.useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (productList.length > 0) {
      setLoading(false)
    }
  }, [isLoading])

  useEffect(() => {
    if (category instanceof TermDepositFeature) {
      switch (category.name) {
        case "3 Month Rates": {
          updateDepositTerm(3)
          appManager.setDepositInvestmentTerm(3)
          break
        }
        case "6 Month Rates": {
          updateDepositTerm(6)
          appManager.setDepositInvestmentTerm(6)
          break
        }
        case "12 Month Rates": {
          updateDepositTerm(12)
          appManager.setDepositInvestmentTerm(12)
          break
        }
        case "24+ Month Rates": {
          updateDepositTerm(24)
          appManager.setDepositInvestmentTerm(24)
          break
        }
      }
    }
  }, [])

  let pageTitle = ""
  let bestRate: undefined | rate = undefined
  let bestTwelveMonthRate: undefined | rate = undefined
  if (category instanceof TermDepositProvider) {
    bestRate = category.getHighestRate()
    bestTwelveMonthRate = category.getHighestRate(12)
    pageTitle = `Compare ${category.name} Term Deposit Rates`
  } else {
    bestRate = category.getHighestFeaturedRate()
    bestTwelveMonthRate = category.getHighestFeaturedRate(12)
    switch (category.name) {
      case "3 Month Rates": {
        pageTitle = `Best 3 Month Term Deposit Rates`
        break
      }
      case "6 Month Rates": {
        pageTitle = `Best 6 Month Term Deposit Rates`
        break
      }
      case "12 Month Rates": {
        pageTitle = `Best 12 Month Term Deposit Rates`
        break
      }
      case "Best Rates": {
        pageTitle = `Best Term Deposit Rates`
        break
      }
    }
  }
  if (bestRate !== null) {
    pageTitle += ` - up to ${((bestRate ? bestRate.interestRate : 0) * 100).toFixed(2)}% p.a.`
  }
  pageTitle += " | Stay or Go"

  const topPickAds = ads
    ? ads
    : bestTwelveMonthRate
      ? {
          offerId: "",
          offerHeading: "",
          offerBullets: { offerBullters: "" },
          disclaimer: { disclaimer: "" },
          product: category.products[bestTwelveMonthRate.productId],
          offer: null,
        }
      : null

  const topRateComponent = () => {
    if (!category || !category.products || !bestTwelveMonthRate) {
      return <div />
    }
    const prod = category.products[bestTwelveMonthRate.productId]

    const minLoanAmount = bestTwelveMonthRate.minInvestment
    const minLoanTerm = TermDepositProduct.getTermInMonths(bestTwelveMonthRate.sogMinTerm)
    const appFreq = TermDepositProduct.getTermInMonths(bestTwelveMonthRate.applicationFreq || "")
    const appFreqString = bestTwelveMonthRate.applicationFreq ? (bestTwelveMonthRate.applicationFreq === "MATURITY" ? "at maturity" : appFreq === 12 ? "annually" : appFreq === 1 ? "monthly" : `every ${appFreq} months`) : null

    const topRate = (bestTwelveMonthRate.interestRate * 100).toFixed(2)

    const isMobile = !useMediaQuery("(min-width:768px)")

    let offer: Offer | null = null
    Object.values(prod.offers).forEach(o => {
      if (offer === null) {
        if (o.active === "Y" && o.landingPageUrl) {
          offer = o
        }
      }
    })

    return (
      <div className={styles.adsContainer}>
        <div className={styles.adsIconContainer}>
          <div>Top Rate</div>
        </div>
        <div className={styles.adsTitle}>
          <div style={{ width: isMobile ? "50%" : "100%", marginRight: isMobile ? "30px" : "0px" }}>{prod.nameLong}</div>
          {true ? (
            <div style={{ width: "70px" }}>
              <LazyLoadImage src={providerImage(prod.providerId)} style={{ width: isMobile ? "100%" : "85%", display: "block", marginLeft: "auto" }} />
            </div>
          ) : null}
        </div>
        <div className={styles.adsOfferHeading}>
          <span className={styles.large}>{topRate}%</span>
          <span> {appFreq === 1 ? "p.m." : "p.a."} for </span>
          <strong>
            {minLoanTerm} month{minLoanTerm !== 1 && "s"}
          </strong>
        </div>
        <div className={styles.adsContent}>
          <div className={styles.adsBody}>
            <div style={{ width: isMobile ? "100%" : "66%" }}>
              <p>{appFreqString && `Interest paid ${appFreqString}`}</p>
              <p>${minLoanAmount.toLocaleString("en-US")} minimum deposit</p>
            </div>
            {/* {!isMobile ? (
              <div style={{ width: "70px" }}>
                <LazyLoadImage src={providerImage(prod.providerId)} style={{ width: "85%", display: "block", marginLeft: "auto" }} />
              </div>
            ) : null} */}
          </div>
          {!isMobile && (
            <div className={styles.adsButtonContainer}>
              <Clickable.Text className={styles.adsButtonSecondary} component={Link} to={"/" + prod.urlSlug} secondary noBorder>
                <p>Learn More</p>
              </Clickable.Text>
            </div>
          )}
        </div>
        {isMobile && (
          <div className={styles.adsButtonContainer}>
            <Clickable.Text className={styles.adsButtonSecondary} component={Link} to={"/" + prod.urlSlug} secondary noBorder>
              <p>Learn More</p>
            </Clickable.Text>
          </div>
        )}
        {offer && (
          <Clickable.Text
            className={styles.adsButton}
            onClick={() => {
              DataCollector.getInstance().addAction(DataCollectorAction.CONTENT_GET_OFFER_SELECTED, { offerId: offer?.offerId, providerId: offer?.providerId })
              AppManager.getInstance().getOffer("Go", offer?.offerId || "", offer, offer?.providerId || 0, prod.providerName)
            }}
          >
            Go to offer
          </Clickable.Text>
        )}
      </div>
    )
  }

  return (
    <Layout title={pageTitle} description={category.getAutoDescription()} urlSlug={`${category.urlSlug ? category.urlSlug : category.nameFormatted}-home-loans`} dateUpdated={dateUpdated} includeMozoDisclaimer>
      {"big4" in categoryJson ? makeSchemaOrgProvider(categoryJson, "td") : null}
      <div className="page-container" style={{ display: "flex", alignItems: "stretch" }}>
        <div className="content-provider-container" style={{ maxWidth: isTablet ? (isWide ? "648px" : "395px") : "960px" }}>
          <ReturnLinkChain linkChain={[{ name: "Term Deposits", to: "/term-deposits/" }]} currentLocationItem={{ name: category.heading }} excludeBottomMargin={isWide} includeFactCheckedDialog={isWide} factCheckedDialogDateUpdated={dateString} />
          <div className={styles.heading}>
            <Typography.H1>Compare {category.heading.replace(" Rates", "")} Term Deposit Rates</Typography.H1>
          </div>
          <div className={styles.description}>
            <Typography.P comp={"content-p"} fontColor={"#A6A6A6"}>
              {category.getAutoDescription()}
            </Typography.P>
            <br />
            <Typography.P comp={"content-p"} fontColor={"#A6A6A6"}>
              Updated {dateString}
            </Typography.P>
            {!isWide && (
              <>
                <br />
                <FactCheckedDialog dateString={dateString} />
              </>
            )}
          </div>
          {topPickAds ? topPickAds.product.id.includes("TD") ? <TermDepositAds ads={topPickAds} /> : topPickAds.product.id.includes("SV") ? <SavingsAccountAds ads={topPickAds} /> : null : null}
          {/* {topRateComponent()} */}
          <div ref={paginationRef} />
          <div style={{ marginBottom: "40px" }}>
            <InputFilter setDepositAmount={updateDepositAmount} setDepositTerm={updateDepositTerm} disableDropdown={fixedTerm} />
          </div>
          <div className={styles.cardValuesHeader}>
            <FilterMenu sort={sort} setSort={setSort} sortList={sortList} filter={filter} setFilter={updateFilter} tempFilter={tempFilter} setTempFilter={updateTempFilter} filterAmounts={filterAmounts} filterList={filterList} mobile={true} linkFirst={linkFirst} setLinkFirst={setLinkFirst} headerType={"term-deposit"} productValuesHeaderComponent={<TermDepositValuesHeader setSort={setSort} sort={sort} />} />
          </div>
          <div style={{ display: "flex", opacity: isLoading ? "0.4" : "1" }}>
            <div style={{ maxWidth: "648px", width: "100%" }}>
              {sort !== "Provider" && (!isTablet || !isWide) ? <ListHeaderProvider isMobile={!isWide || isTablet}>{sort}</ListHeaderProvider> : null}
              {isTablet && isWide ? (
                <div style={{ display: "flex" }}>
                  <div style={{ width: "337.5px", marginRight: "50px" }}>
                    <div className={styles.cardValuesHeaderTablet}>
                      <div style={{ marginLeft: "19px", marginRight: "19px", display: "flex" }}>
                        <div style={{ height: "100%", width: "100%" }}>
                          <TermDepositValuesHeader forFilter={true} setSort={setSort} sort={sort} />
                        </div>
                      </div>
                    </div>
                    {sort !== "Provider" ? <ListHeaderProvider isMobile>{sort}</ListHeaderProvider> : null}
                    {productList}
                    <Pagination paginatedProductLists={paginatedProductLists} page={page} setPage={setPage} totalAmount={totalAmount} offersPerPage={offersPerPage} scrollRef={paginationRef} />
                    {/* {"big4" in categoryJson && productListPage2.length > 0 ? productListPage1 : productList}
                    {"big4" in categoryJson && productListPage2.length > 0 ? (
                      <div>
                        <div
                          style={{ cursor: "pointer", color: "#202020", width: "100%", height: "40px", display: "flex", justifyContent: "space-between", marginTop: "50px", alignItems: "center" }}
                          onClick={() => {
                            setOpen(!open)
                          }}
                          role="button"
                          tabIndex={0}
                        >
                          <div style={{ textAlign: "left", height: "18px", display: "block", lineHeight: "18px", fontSize: "16px", fontFamily: "Arboria-Bold, sans-serif" }}>See more offers</div>
                          {open ? <ExpandLess /> : <ExpandMore />}
                        </div>
                        <Collapse in={open} timeout="auto" unmountOnExit>
                          {productListPage2}
                        </Collapse>
                      </div>
                    ) : null} */}
                  </div>
                  <div style={{ marginTop: "70px" }}>
                    <div style={{ fontSize: "18px", fontFamily: "Arboria-Bold", color: "#4D4D4D", marginBottom: "43px" }}>{totalAmount === 0 ? "No Offers" : currentOfferShown + " of " + totalAmount}</div>
                    <FilterMenu sort={sort} setSort={setSort} sortList={sortList} filter={filter} setFilter={updateFilter} tempFilter={tempFilter} setTempFilter={updateTempFilter} filterAmounts={filterAmounts} filterList={filterList} linkFirst={linkFirst} setLinkFirst={setLinkFirst} headerType={"term-deposit"} />
                  </div>
                </div>
              ) : (
                <div>
                  {productList}
                  <Pagination paginatedProductLists={paginatedProductLists} page={page} setPage={setPage} totalAmount={totalAmount} offersPerPage={offersPerPage} scrollRef={paginationRef} />
                  {/* {"big4" in categoryJson && productListPage2.length > 0 ? productListPage1 : productList}
                  {"big4" in categoryJson && productListPage2.length > 0 ? (
                    <div>
                      <div
                        style={{ cursor: "pointer", color: "#202020", width: "100%", height: "40px", display: "flex", justifyContent: "space-between", marginTop: "50px", alignItems: "center" }}
                        onClick={() => {
                          setOpen(!open)
                        }}
                        role="button"
                        tabIndex={0}
                      >
                        <div style={{ textAlign: "left", height: "18px", display: "block", lineHeight: "18px", fontSize: "16px", fontFamily: "Arboria-Bold, sans-serif" }}>See more offers</div>
                        {open ? <ExpandLess /> : <ExpandMore />}
                      </div>
                      <Collapse in={open} timeout="auto" unmountOnExit>
                        {productListPage2}
                      </Collapse>
                    </div>
                  ) : null} */}
                </div>
              )}
            </div>
            {!isTablet ? (
              <div style={{ marginTop: "10px", marginLeft: "43px" }}>
                <div style={{ fontSize: "18px", fontFamily: "Arboria-Bold", color: "#4D4D4D", marginBottom: "43px" }}>{totalAmount === 0 ? "No Offers" : currentOfferShown + " of " + totalAmount}</div>
                <FilterMenu sort={sort} setSort={setSort} sortList={sortList} filter={filter} setFilter={updateFilter} tempFilter={tempFilter} setTempFilter={updateTempFilter} filterAmounts={filterAmounts} filterList={filterList} linkFirst={linkFirst} setLinkFirst={setLinkFirst} headerType={"term-deposit"} />
              </div>
            ) : null}
          </div>
          <div style={{ maxWidth: "648px" }}>
            {qa ? (
              <div style={{ marginTop: "60px" }}>
                {/* {compileSchemaOrgQa(qa.qa.qa)} */}
                {/* <div className={styles.listHeader}>
                    <div style={{ marginLeft: "20px", color: "white", fontFamily: "Arboria-Bold, sans-serif", fontWeight: 700, lineHeight: "40px", fontSize: "20px" }}>{"FAQs"}</div>
                  </div> */}
                <ListHeaderProvider>{"FAQs"}</ListHeaderProvider>
                {/* <div>
                  <Markdown md={qa.qa.qa} isArticle={true} />
                </div> */}
                <Faq faqContent={qa.qa.qa} />
              </div>
            ) : null}
            {/* <TermDepositDisclaimer isDisclaimerOpen isProvider /> */}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default ProviderTemplate
