// extracted by mini-css-extract-plugin
export var adsBody = "style-module--ads-body--ef40f";
export var adsButton = "style-module--ads-button--b0a16";
export var adsButtonContainer = "style-module--ads-button-container--9cd4b";
export var adsButtonSecondary = "style-module--ads-button-secondary--d82f3";
export var adsContainer = "style-module--ads-container--05fa6";
export var adsContent = "style-module--ads-content--0c305";
export var adsDisclaimer = "style-module--ads-disclaimer--bb2a9";
export var adsIconContainer = "style-module--ads-icon-container--fcc1a";
export var adsOfferHeading = "style-module--ads-offer-heading--1a16c";
export var adsTitle = "style-module--ads-title--60c5f";
export var button = "style-module--button--48559";
export var cardImage = "style-module--card-image--9b492";
export var cardImageImg = "style-module--card-image-img--f9b40";
export var cardRow = "style-module--card-row--4a484";
export var cardValuesHeader = "style-module--card-values-header--41608";
export var cardValuesHeaderTablet = "style-module--card-values-header-tablet--e6ab9";
export var description = "style-module--description--de37c";
export var dropdown = "style-module--dropdown--1ecd5";
export var heading = "style-module--heading--3d943";
export var innerProductComponent = "style-module--inner-product-component--09b4c";
export var large = "style-module--large--e2d6a";
export var listHeader = "style-module--list-header--4e020";
export var listHeaderMobile = "style-module--list-header-mobile--ea41a";
export var productComponent = "style-module--product-component--c3fd6";
export var rightContainer = "style-module--right-container--b395d";