import Provider from "../Provider"
import Product from "../Product"
import Reward from "../Reward"
import TermDepositProduct, { rate } from "../product-models/TermDepositProduct"

let obj: any = null
export default class TermDepositProvider extends Provider {

  // = CUSTOM METHODS =
  public getHighestRate(maxTerm?: number): rate | undefined {
    let maxRate: undefined | rate = undefined
    Object.keys(this.products).forEach(pId => {
      const tdProd = this.products[pId] as TermDepositProduct
      Object.keys(tdProd.rates).forEach((rId) => {
        const rate = tdProd.rates[rId]
        const rateMinTerm = TermDepositProduct.getTermInMonths(rate.sogMinTerm)
        if ((!maxRate || rate.interestRate > maxRate.interestRate ||
            (rate.interestRate === maxRate.interestRate
              && rateMinTerm < TermDepositProduct.getTermInMonths(maxRate.sogMinTerm)
            )
          ) && (maxTerm === undefined || maxTerm >= rateMinTerm)
        ) {
          maxRate = rate
        }
      })
    })
    return maxRate
  }

  // = ABSTRACT OVERRIDES =
  public getAutoDescription(): string {
    return this.autoDescription
  }

  // Alternative Products
  public getAlternateProductsByProduct(product: Product): Product[] {
    const alternativeProducts: Product[] = []
    // TODO: implement this
    return alternativeProducts
  }

  public getUnfilteredProductsByProduct(product: Product): Product[] {
    const alternativeProducts: Product[] = []
    if (!(product instanceof TermDepositProduct)) {
      return alternativeProducts
    }
    Object.keys(this.products).forEach(productId => {
      if (productId.toString() !== product.id.toString()) {
        // TODO: define how to show similar products
      }
    })
    return alternativeProducts
  }

  public getAlternativeProductList(product: Product, makeProductComponent: (product: Product, key: number, isLast?: boolean | undefined) => JSX.Element): JSX.Element[] {
    // Alternative Products
    const alternativeProductList: JSX.Element[] = this.getAlternateProductsByProduct(product).map((singleProduct, productKey) => makeProductComponent(singleProduct, productKey, productKey === this.getAlternateProductsByProduct(product).length - 1))
    return alternativeProductList
  }

  // getProductList
  public getProductList(makeProductComponent: (product: Product, key: number, rateId: string, isLast?: boolean | undefined) => JSX.Element, makeCategoryHeader: (headerName: string) => JSX.Element): JSX.Element[] {
    const productList: JSX.Element[] = []
    const products = Object.values(this.products)
    if (products.length > 0) {
      productList.push(makeCategoryHeader(this.name + " Term Deposits"))
    }

    products.forEach((prod, key) => {
      const tdProd = prod as TermDepositProduct
      Object.keys(tdProd.rates).forEach((rateId, i) => {
        productList.push(makeProductComponent(prod, key, rateId, i === Object.keys(tdProd.rates).length - 1))
      })
    })
    return productList
  }

  // = AUTOMATED FUNCTIONS =
  public static Create(d: any, rewards: { [id: string]: Reward }, field: string = "root"): Provider {
    if (!field) {
      obj = d
      field = "root"
    }
    if (d === null || d === undefined) {
      throwNull2NonNull(field, d)
    } else if (typeof d !== "object") {
      throwNotObject(field, d, false)
    } else if (Array.isArray(d)) {
      throwIsArray(field, d, false)
    }
    checkString(d.id, false, field + ".id")
    d.id = d.id
    checkString(d.name, false, field + ".name")
    d.name = d.name
    d.nameFormatted = d.name.toLowerCase().replace(/ /g, "-")
    checkBoolean(d.big4, false, field + ".big4")
    // Create Product Dictionary
    const productsDict: { [id: string]: Product } = {}
    Object.keys(d.products).forEach(productId => {
      productsDict[productId] = TermDepositProduct.Create(d.products[productId], rewards, d.name, d.participant, field + ".products." + productId)
    })
    d.products = productsDict

    let maxAnnualRate = Number.MIN_VALUE
    let maxMaturityRate = Number.MIN_VALUE
    let maxRate = Number.MIN_VALUE

    Object.keys(d.products).forEach(productId => {
      const product = TermDepositProduct.Initialise(d.products[productId])
      
      Object.keys(product.rates).forEach(rateId => {
        const rate = product.rates[rateId]
        if (rate.interestRate > maxRate) {
          maxRate = rate.interestRate
        }
        if (rate.interestRate > maxMaturityRate && rate.applicationFreq === "MATURITY") {
          maxMaturityRate = rate.interestRate
        }
        if (rate.interestRate > maxAnnualRate && (rate.applicationFreq === "P12M"
          || (rate.applicationFreq === "MATURITY" && rate.sogMinTerm === "P12M"))
        ) {
          maxAnnualRate = rate.interestRate
        }
      })
    })
    
    d.autoDescription = "Compare the latest " + d.name + " term deposit rates."
    d.autoDescription += ` Rates up to ${(maxRate * 100).toFixed(2)}% p.a.`
    // d.autoDescription += (
    //   maxAnnualRate !== Number.MIN_VALUE && maxAnnualRate ? ` Rates up to ${(maxAnnualRate * 100).toFixed(2)}% p.a.`
    //   : (
    //     maxMaturityRate !== Number.MIN_VALUE ? ` Rates up to ${(maxMaturityRate * 100).toFixed(2)}% at maturity.`
    //     : (
    //       maxRate !== Number.MIN_VALUE ? ` Rates up to ${(maxRate * 100).toFixed(2)}% p.a.` : ''
    //     )
    //   )
    // )

    return new TermDepositProvider(d)
  }

  public static Initialise(d: any): Provider {
    return new TermDepositProvider(d)
  }

  protected constructor(d: any) {
    super(d)
  }
}

function throwNull2NonNull(field: string, d: any): never {
  return errorHelper(field, d, "non-nullable object", false)
}
function throwNotObject(field: string, d: any, nullable: boolean): never {
  return errorHelper(field, d, "object", nullable)
}
function throwIsArray(field: string, d: any, nullable: boolean): never {
  return errorHelper(field, d, "object", nullable)
}
function checkNumber(d: any, nullable: boolean, field: string): void {
  if (typeof d !== "number" && (!nullable || (nullable && d !== null && d !== undefined))) {
    errorHelper(field, d, "number", nullable)
  }
}
function checkBoolean(d: any, nullable: boolean, field: string): void {
  if (typeof d !== "boolean" && (!nullable || (nullable && d !== null && d !== undefined))) {
    errorHelper(field, d, "boolean", nullable)
  }
}
function checkString(d: any, nullable: boolean, field: string): void {
  if (typeof d !== "string" && (!nullable || (nullable && d !== null && d !== undefined))) {
    errorHelper(field, d, "string", nullable)
  }
}
function errorHelper(field: string, d: any, type: string, nullable: boolean): never {
  if (nullable) {
    type += ", null, or undefined"
  }
  throw new TypeError("Expected " + type + " at " + field + " but found:\n" + JSON.stringify(d) + "\n\nFull object:\n" + JSON.stringify(obj))
}
