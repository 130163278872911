import TermDepositProduct, { rate } from "../product-models/TermDepositProduct"
import Product from "../Product"
import Feature from "../Feature"

let obj: any = null
export default class TermDepositFeature extends Feature {

  public readonly descriptionFirstLine: string;
  public readonly featuredRates: rate[] = [];

  // = ABSTRACT OVERRIDES

  // TODO: remove this?
  public getFilterAmounts(): { [id: string]: number } {
    // filterAmounts
    const filterAmounts: { [id: string]: number } = {
      "Major Banks": 0,
      "Challengers": 0,
      "Non-banks": 0,
    }

    Object.keys(this.products).forEach((productId: string) => {
      const product = this.products[productId] as TermDepositProduct
      const productFilterAmounts = product.getFilterAmounts()
      Object.keys(productFilterAmounts).forEach(id => {
        if (filterAmounts[id] !== undefined) {
          filterAmounts[id] += productFilterAmounts[id]
        } else {
          filterAmounts[id] = productFilterAmounts[id]
        }
      })
    })

    return filterAmounts
  }

  public getTitle(): string {
    return ""
  }

  public getAutoDescription(): string {
    let autoDescription = this.descriptionFirstLine || "Compare the best " + this.name + " term deposit rates."
    const highestRate = this.getHighestFeaturedRate()
    autoDescription += ` Rates up to ${((highestRate ? highestRate.interestRate : 0) * 100).toFixed(2)}% p.a.`
    return autoDescription
  }

  public getHighestFeaturedRate(maxTerm?: number): rate | undefined {
    let maxRate: rate | undefined = undefined
    this.featuredRates.forEach(rate => {
      const rateMinTerm = TermDepositProduct.getTermInMonths(rate.sogMinTerm)
      if ((!maxRate || rate.interestRate > maxRate.interestRate ||
          (rate.interestRate === maxRate.interestRate
            && rateMinTerm < TermDepositProduct.getTermInMonths(maxRate.sogMinTerm)
          )
        ) && (maxTerm === undefined || maxTerm >= rateMinTerm)
      ) {
        maxRate = rate
      }
    })
    return maxRate
  }

  // getProductList
  public getProductList(makeProductComponent: (product: Product, key: number, rateId: string, isLast?: boolean | undefined, offerFilter?: string) => JSX.Element, makeCategoryHeader: (headerName: string) => JSX.Element): JSX.Element[] {
    const productList: JSX.Element[] = []

    const sortedProducts: Product[] = []
    Object.values(this.products).forEach(p => {
      sortedProducts.push(p)
    })
    sortedProducts.sort((a, b) => this.compare(a, b))

    // Categorise by providers
    const productsByProviders: { [id: string]: Product[] } = {}
    sortedProducts.forEach(product => {
      const productProvider: string = product.providerName

      if (productProvider in productsByProviders) {
        productsByProviders[productProvider].push(product)
      } else {
        productsByProviders[productProvider] = [product]
      }
    })

    this.featuredRates.slice(0,0)

    // Fill product list
    Object.keys(productsByProviders).forEach(providerName => {
      const products: Product[] = productsByProviders[providerName]

      // Add Provider Header to list
      if (products.length > 0) {
        productList.push(makeCategoryHeader(providerName))
      }

      // collect rates by term
      const ratesByTerm: {[key: string]: rate[]} = {}
      if (this.name === "Best Rate") {
        // find the best rates
        products.forEach((prod: any, key: number) => {
          const tdProd = prod as TermDepositProduct
          Object.keys(tdProd.rates).forEach((rateId, i) => {
            const rate = tdProd.rates[rateId]
            const term = `${TermDepositProduct.getTermInMonths(rate.sogMinTerm)}`
            if (term in ratesByTerm) {
              ratesByTerm[term].push(rate)
            } else {
              ratesByTerm[term] = [rate]
            }
          })
        })
        Object.keys(ratesByTerm).forEach((t) => {
          ratesByTerm[t].sort((a,b) => (b.interestRate - a.interestRate))
          // limit to top 25% for the chosen term
          ratesByTerm[t].slice(0, Math.ceil(ratesByTerm[t].length / 4.0))
        })
      }

      // Add Product Components to list
      products.forEach((prod: any, key: number) => {
        const tdProd = prod as TermDepositProduct
        Object.keys(tdProd.rates).forEach((rateId, i) => {
          const rate = tdProd.rates[rateId]
          // add if eligible for feature
          const minTerm = TermDepositProduct.getTermInMonths(rate.sogMinTerm)
          const maxTerm = Math.max(TermDepositProduct.getTermInMonths(rate.sogMaxTerm || ''), minTerm + 1)

          switch (this.name) {
            case "3 Month Rates": {
              if (minTerm > 4 || maxTerm <= 1) {return}
              break;
            }
            case "6 Month Rates": {
              if (minTerm > 9 || maxTerm <= 4) {return}
              break;
            }
            case "12 Month Rates": {
              if (minTerm > 18 || maxTerm <= 9) {return}
              break;
            }
            case "Best Rate": {
              if (ratesByTerm[`${minTerm}`].indexOf(rate) < 0) {return}
              break;
            }
          }

          this.featuredRates.push(rate)
          productList.push(makeProductComponent(prod, key, rateId, i === Object.keys(tdProd.rates).length - 1))
        })
      })
    })

    return productList
  }

  // = AUTOMATED FUNCTIONS =
  public static Parse(d: string): TermDepositFeature {
    return TermDepositFeature.Create(JSON.parse(d))
  }
  public static Create(d: any, field: string = "root"): TermDepositFeature {
    if (!field) {
      obj = d
      field = "root"
    }
    if (d === null || d === undefined) {
      throwNull2NonNull(field, d)
    } else if (typeof d !== "object") {
      throwNotObject(field, d, false)
    } else if (Array.isArray(d)) {
      throwIsArray(field, d, false)
    }
    checkString(d.name, false, field + ".name")
    checkString(d.urlSlug, true, field + ".urlSlug")
    checkString(d.descriptionFirstLine, true, field + ".urlSlug")
    d.nameFormatted = d.name
    d.heading = d.name
    d.descriptionFirstLine = d.descriptionFirstLine || ''
    d.products = {}
    return new TermDepositFeature(d)
  }
  public static Initialise(d: any): TermDepositFeature {
    return new TermDepositFeature(d)
  }
  private constructor(d: any) {
    super(d)
    this.descriptionFirstLine = d.descriptionFirstLine
  }
}

function throwNull2NonNull(field: string, d: any): never {
  return errorHelper(field, d, "non-nullable object", false)
}
function throwNotObject(field: string, d: any, nullable: boolean): never {
  return errorHelper(field, d, "object", nullable)
}
function throwIsArray(field: string, d: any, nullable: boolean): never {
  return errorHelper(field, d, "object", nullable)
}
function checkString(d: any, nullable: boolean, field: string): void {
  if (typeof d !== "string" && (!nullable || (nullable && d !== null && d !== undefined))) {
    errorHelper(field, d, "string", nullable)
  }
}
function errorHelper(field: string, d: any, type: string, nullable: boolean): never {
  if (nullable) {
    type += ", null, or undefined"
  }
  throw new TypeError("Expected " + type + " at " + field + " but found:\n" + JSON.stringify(d) + "\n\nFull object:\n" + JSON.stringify(obj))
}
